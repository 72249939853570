module.exports = new Promise(resolve => {
      const remoteUrlWithVersion = '/shared-ui/shared-ui.remoteEntry.js'
          const script = document.createElement('script')
          script.src = remoteUrlWithVersion
          script.onload = () => {
            // the injected script has loaded and is available on window
            // we can now resolve this Promise
            const proxy = {
              get: (request) => window.shared_ui.get(request),
              init: (arg) => {
                try {
                  return window.shared_ui.init(arg)
                } catch(e) {
                  console.log('remote container already initialized')
                }
              }
            }
            resolve(proxy)
          }
          // inject this script with the src set to the versioned remoteEntry.js
          document.head.appendChild(script);
        })
        ;